import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { WHITE, LOGO_SECONDARY } from '../../util/theme';
import img from '../../assets/images/Sveta.png';
// import img from '../../assets/images/Svetlana_square.jpg';
import StringTable from '../../i18n/en-us/strings.json'; 

//******************************************************************************
const STRINGS = StringTable.about.SvetlanaBIO;

//******************************************************************************
const useStyles = makeStyles(theme =>
({
  container: {
    display: 'flex',
    flex: '1 0 auto',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    backgroundColor: 'DCDCDC',
    padding: 70,
  },
  description:{
    flex:'0 0 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-end',
    position: 'relative',
    top: 30,
    right: 40,
    backgroundColor: WHITE.css(),
    width: '60%',
    zIndex: 10,
    padding: [[10, 20, 10, 20]],
  },
  descriptionTitle: {
    flex: '0 0 auto',
    color: LOGO_SECONDARY.css(),
    fontSize: 24,
    cursor: 'default',
  },
  descriptionText: {
    flex: '1 0 auto',
    fontSize: 14,
    cursor: 'default',
    textAlign: 'right',
  },
  img: {
    flex: '0 0 auto',
    position: 'relative',
    top: -10,
    objectFit: 'cover',
    width: '30%',
    zIndex: 1,
  },
}));
//------------------------------------------------------------------------------
export function SvetlanaBIO(props)
{
  const classes = useStyles(props);
  return(
    <div className={clsx(props.className, classes.container)}>
      <img className={classes.img} src={img} alt={''}/>
      <div className={classes.description}>
        <div className={classes.descriptionTitle}>{STRINGS.title1}
        <br/>
        {STRINGS.title2}
        </div>
          <p className={classes.descriptionText}>
            {STRINGS.contentL1}
            <br/>
            <br/>
            {STRINGS.contentL2}
            <br/>
            <br/>
            {STRINGS.contentL3}
            <br/>
            <br/>
            {STRINGS.contentL4}
            </p>
      </div>
    </div>
  );
}
//******************************************************************************