import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { AboutUsTop } from '../components/about/about-top';
import { SvetlanaBIO } from '../components/about/bio-sveta';
import { DmitryBIO } from '../components/about/bio-dmitry';
import { GetInTouch } from '../components/index/get-in-touch';

//******************************************************************************
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
  },
  item: {
    minHeight: '50vh',
    border: 'solid 1px blue',
  },
}));
//------------------------------------------------------------------------------
export function AboutPage(props)
{
  const classes = useStyles(props);
  return (
    <div className={classes.container}>
      <AboutUsTop/>
      <SvetlanaBIO/>
      <DmitryBIO/>
      <GetInTouch id={'contactus'}/>
    </div>
  );
}
//------------------------------------------------------------------------------
export default AboutPage;
//******************************************************************************