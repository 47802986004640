import React from 'react';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { LOGO_SECONDARY } from '../../util/theme';
import background from '../../assets/images/table.jpg';
import StringTable from '../../i18n/en-us/strings.json'; 

//******************************************************************************
const STRINGS = StringTable.about.AboutUsTop;

//******************************************************************************
const useStyles = makeStyles(theme =>
({
  container: {
    display: 'flex',
    flex: '1 0 0',
    flexFlow: 'row nowrap',
    justifyContent: 'center',
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    padding: 60,
  },
  description:{
    flex:'0 0 auto',
    display: 'flex',
    flexFlow: 'column nowrap',
    position: 'relative',
    width: '85%',
    backgroundColor: '#0d203780',
    zIndex: 10,
    padding: 30,
  },
  descriptionTitle: {
    flex: '0 0 auto',
    color: LOGO_SECONDARY.css(),
    fontSize: 35,
    cursor: 'default',
  },
  descriptionText: {
    flex: '1 0 auto',
    fontSize: 20,
    cursor: 'default',
    color: '#ffffff',
  },
}));
//------------------------------------------------------------------------------
export function AboutUsTop(props)
{
  const classes = useStyles(props);
  return(
    <div className={clsx(props.className, classes.container)}>
      <div className={classes.description}>
        <div className={classes.descriptionTitle}>{STRINGS.title}</div>
        {/* <div className={classes.descriptionText}>_____________________________________________________________________________________</div> */}
          <p className={classes.descriptionText}>
          {STRINGS.contentL1}
          <br/>
          <br/>
          {STRINGS.contentL2}
          <br/>
          <br/>
          {STRINGS.contentL3}
          <br/>
          <br/>
          {STRINGS.contentL4}
          <br/>
          <br/>
          {STRINGS.contentL5}
          <br/>
          <br/>
          {STRINGS.contentL6}                                                                                                                                                              
          </p>
        </div>
    </div>
  );
}
//******************************************************************************